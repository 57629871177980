<template>
  <section class="section">
    <div class="section-header">
      <h1>{{ $route.name }}</h1>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header align-items-center justify-content-between">
            <h4>All Issues</h4>
            <router-link
              to="/admin/issues/create"
              class="btn btn-icon icon-left btn-primary"
              style="margin-left: 15px"
              ><i class="far fa-plus"></i> Create Issues</router-link
            >
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-striped">
                <tr>
                  <th>#</th>
                  <th>Title</th>
                  <th>Action</th>
                </tr>
                <tr v-for="(issue, index) in issues" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ issue.title }}</td>
                  <td>
                    <a
                      class="btn btn-danger btn-action"
                      data-toggle="tooltip"
                      title="Delete"
                      @click.prevent="deleteIssue(issue.id)"
                      ><i class="fas fa-trash"></i
                    ></a>
                  </td>
                </tr>
              </table>
            </div>
            <div class="d-flex justify-content-between align-items-center">
              <h6>
                <strong>Total Issues:</strong> {{ issues.length }}
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import GeneralHelpers from "@/functions/actions/GeneralRequest";
import { mapState } from "vuex";
export default {
  name: "Users",
  computed: {
    ...mapState(["loading"]),
  },
  mounted() {
    this.retrieveIssues();
  },
  data() {
    return {
      issues: [],
    };
  },
  methods: {
    async retrieveIssues() {
      const response = await GeneralHelpers.getIssues();
      if (response.status) {
        this.issues = response.issues;
      } else {
        this.showAlert(
          "Error occured",
          `${response.message}. Kindly reload the page`,
          "error"
        );
      }
    },
    async deleteIssue(issueId) {
      this.$confirm("Are you sure?").then(async () => {
        const response = await GeneralHelpers.deleteIssue(issueId)
        if (response.status) {
          this.issues = this.issues.filter(issue => issue.id != issueId)
          this.showAlert('Success', 'Issue deleted successfully', 'success')
        } else {
          this.showAlert('Error occured', response.message, 'error')
        }
      });
    },
    showAlert(title, text, type) {
      this.$fire({
        title,
        text,
        type: type ? type : "warning",
      });
    },
  },
};
</script>
<style lang="">
</style>